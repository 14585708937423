<template>
  <div>
    <div class="d-flex justify-content-end align-content-center">
      <div class="my-auto">
        <b-button
          class="font-weight-bolder"
          variant="light-primary"
          @click="$router.push({ name: 'teacherAttendanceExport' })"
          >Details
        </b-button>
      </div>
    </div>
    <EmployeeMonthlyAttendanceTable
      :items="items"
      :pagination="pagination"
      :is-busy="isBusyStore"
      @changePage="handlePageChange"
      @showIndividualAttendance="openMonthlyModal"
      @away-count-clicked="showAwayModal"
      @leave-count-clicked="showLeaveModal"
    >
    </EmployeeMonthlyAttendanceTable>

    <TeacherIndividualMonthlyAttendanceModal
      :employee="employee"
      :date="date"
      :date-info="dateInfo"
    ></TeacherIndividualMonthlyAttendanceModal>

    <!-- begin:: teacher employee away modal    -->
    <SchoolEmployeeIndividualAwayModal
      :aways="aways"
      :is-busy="isBusy"
      :pagination="awayPagination"
      @page-change="handleAwayChange"
    >
    </SchoolEmployeeIndividualAwayModal>
    <!-- end:: teacher employee away modal    -->

    <!-- begin:: teacher employee leave modal    -->
    <SchoolEmployeeIndividualLeaveModal
      :leaves="leaves"
      :is-busy="isBusy"
      :pagination="leavePagination"
      @page-change="handleLeavePage"
    ></SchoolEmployeeIndividualLeaveModal>
    <!-- end:: teacher employee leave modal    -->
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import EmployeeMonthlyAttendanceTable from "@/modules/school/components/shared/management/attendance/table/EmployeeMonthlyAttendanceTable";
import TeacherIndividualMonthlyAttendanceModal from "@/modules/school/components/management/attendance/teacher/TeacherIndividualMonthlyAttendanceModal";
import SchoolEmployeeIndividualAwayModal from "@/modules/school/components/management/attendance/SchoolEmployeeIndividualAwayModal";
import SchoolEmployeeIndividualLeaveModal from "@/modules/school/components/management/attendance/SchoolEmployeeIndividualLeaveModal";

export default {
  name: "TeacherMonthlyAttendance",
  components: {
    SchoolEmployeeIndividualLeaveModal,
    SchoolEmployeeIndividualAwayModal,
    TeacherIndividualMonthlyAttendanceModal,
    EmployeeMonthlyAttendanceTable,
  },
  data() {
    return {
      isBusy: false,
      employee: {},
      date: new Date(dayjs().format()),
      attendance: {},
      dateInfo: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherMonthlyAttendanceList")
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    /**
     * @param data (year & month)
     */
    filterDate(data) {
      console.log("Date ", data);
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: data.year,
        month: data.month,
      });

      this.$store.dispatch("changeTeacherMonthlyAttendanceListDate", {
        date: date.format(),
      });

      this.fetch();
    },

    /**
     * Change page
     *
     * @param page
     */
    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setTeacherMonthlyAttendancePage", page);
      this.fetch();
    },

    /**
     * Open Modal
     *
     * @param data
     */
    openMonthlyModal(data) {
      console.log("data", data);

      const item = data.item;
      console.log("Selected item ", item);
      this.employee = item;
      console.log("Checking employee", this.employee);
      this.date = this.dateFilter
        ? new Date(this.dateFilter)
        : new Date(dayjs().format());

      console.log("Selected date : ", this.date);

      this.$store.commit("setTeacherIndividualAttendancePage", 1);

      this.$store.dispatch("fetchTeacherIndividualAttendance", {
        id: this.employee.id,
        date: this.date,
        date_type: this.dateType,
        from_date: this.filterDate.from_date,
        to_date: this.filterDate.to_date,
      });

      this.dateInfo = {
        date: this.date,
        date_type: this.dateType,
        from_date: this.filterDate.from_date,
        to_date: this.filterDate.to_date,
      };

      this.$bvModal.show("employee-monthly-attendance-modal");
    },

    /**
     * Show school employee leave modal.
     *
     * @param data
     */
    showAwayModal(data) {
      console.log(data);
      console.log("Date filter is", this.dateFilter);

      this.$store.dispatch("changeIndividualStaffAwayListFilter", {
        date: this.dateFilter,
        staffId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualTeacherAwayList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-away-modal");
    },

    /**
     *
     */
    handleAwayChange(page) {
      console.log("Page : ", page);

      this.$store.commit("setIndividualStaffAwayListPage", page);
      this.$store.dispatch("fetchIndividualTeacherAwayList");
    },

    /**
     * Show school employee leave modal.
     *
     * @param data
     */
    showLeaveModal(data) {
      console.log(data);

      this.$store.dispatch("changeIndividualStaffLeaveListFilter", {
        date: this.dateFilter,
        staffId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualTeacherLeaveList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-leave-modal");
    },

    /**
     * Handle teacher individual leave page change event.
     *
     * @param page
     */
    handleLeavePage(page) {
      console.log(page);

      this.$store.commit("setIndividualStaffLeaveListPage", page);
      this.$store.dispatch("fetchIndividualTeacherLeaveList");
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherMonthlyAttendanceList",
      pagination: "getTeacherMonthlyAttendancePagination",
      dateFilter: "getTeacherMonthlyAttendanceListDate",
      leaves: "getIndividualStaffLeaveList",
      aways: "getIndividualStaffAwayList",
      leavePagination: "getIndividualTeacherLeaveListPagination",
      awayPagination: "getIndividualStaffAwayListPagination",
      isBusyStore: "getTeacherMonthlyListAttendanceBusy",
      filterDate: "getTeacherMonthlyListAttendanceFilter",
      dateType: "getTeacherMonthlyAttendanceDateType",
    }),
  },
};
</script>

<style scoped></style>
