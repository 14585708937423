<template>
  <div>
    <CommonAttendanceUpdateTimeModal
      :attendance="attendance"
      :employee="employee"
      :hold="isBusy"
      @onSubmitClick="updateAttendance"
    ></CommonAttendanceUpdateTimeModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonAttendanceUpdateTimeModal from "@/modules/core/components/attendance/CommonAttendanceUpdateTimeModal";

export default {
  name: "TeacherAttendanceUpdateTimeModal",
  components: { CommonAttendanceUpdateTimeModal },
  props: {
    employee: {
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateAttendance(form) {
      this.$store
        .dispatch("updateTeacherAttendanceTime", {
          form: form,
        })
        .then(() => {
          this.$bvModal.msgBoxOk("Manual entry.", {
            centered: true,
            title: "Success",
          });
          this.$emit("formSubmitted", form);
        });
    },
  },
  computed: {
    ...mapGetters({
      isBusy: "getTeacherAttendanceTimeBusy",
    }),
  },
};
</script>

<style scoped></style>
