<template>
  <div>
    <CommonAttendanceManageModal
      :employee="employee"
      :attendance="attendance"
      :changed-id="attendanceStatusId"
      @onSubmitClick="handleManageSubmission"
    ></CommonAttendanceManageModal>
  </div>
</template>

<script>
import CommonAttendanceManageModal from "@/modules/core/components/attendance/CommonAttendanceManagementModal";
export default {
  name: "TeacherAttendanceManagementModal",
  components: { CommonAttendanceManageModal },
  props: {
    employee: {
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Handle the submission of per-day attendance
     *
     * @param form
     */
    handleManageSubmission(form) {
      console.log("Form", form);
      console.log("Attendance", this.attendance);

      this.$store
        .dispatch("submitTeacherManualAttendance", {
          form: form,
        })
        .then(() => {
          this.$bvModal.msgBoxOk("Manual entry.", {
            centered: true,
            title: "Success",
          });
          this.$emit("formSubmitted", form);
        });
    },
  },
  computed: {
    attendanceStatusId() {
      return this.attendance.status !== undefined
        ? this.attendance.status.id
        : 1;
    },
  },
};
</script>

<style scoped></style>
