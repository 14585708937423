<template>
  <div>
    <IndividualEmployeeMonthlyAttendanceModal
      :items="items"
      :date="date"
      :employee="employee"
      :records="records"
      :is-busy="fetchBusy"
      :title="title"
      :pagination="pagination"
      @showClock="openClock"
      @showManageModal="openManage"
      @showUpdateModal="showUpdateModal"
      @changePage="handlePageChange"
    >
    </IndividualEmployeeMonthlyAttendanceModal>

    <!-- begin: Support Modals -->
    <ClockRecordModal :is-busy="isBusy" :records="records"></ClockRecordModal>
    <TeacherAttendanceManagementModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="handleManageSubmission"
    ></TeacherAttendanceManagementModal>

    <TeacherAttendanceUpdateTimeModal
      :employee="employee"
      :attendance="att"
      @formSubmitted="handleManageSubmission"
    ></TeacherAttendanceUpdateTimeModal>
    <!-- end: Support Modals -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import IndividualEmployeeMonthlyAttendanceModal from "@/modules/school/components/shared/management/attendance/modal/IndividualEmployeeMonthlyAttendanceModal";
import ClockRecordModal from "@/modules/core/components/attendance/ClockRecordModal";
import TeacherAttendanceManagementModal from "@/modules/school/components/management/attendance/teacher/TeacherAttendanceManagementModal";
import TeacherAttendanceUpdateTimeModal from "@/modules/school/components/management/attendance/teacher/TeacherAttendanceUpdateTimeModal";

export default {
  name: "TeacherIndividualMonthlyAttendanceModal",
  components: {
    TeacherAttendanceUpdateTimeModal,
    TeacherAttendanceManagementModal,
    ClockRecordModal,
    IndividualEmployeeMonthlyAttendanceModal,
  },
  props: {
    employee: {
      required: true,
      type: Object,
    },
    date: {
      type: Date,
      required: true,
      default: new Date(dayjs().format()),
    },
    dateInfo: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isBusy: false,
      attendance: {},
      att: {},
      title: "Teacher Monthly Attendance",
    };
  },
  methods: {
    test() {
      console.log("Test Button.");
      console.log("Checking employee ", this.employee);
    },

    openClock(data) {
      console.log(data);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Teacher has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }

      this.isBusy = true;
      this.$bvModal.show("clock-record-modal");

      this.$store
        .dispatch("fetchTeacherClockRecordList", {
          id: this.employee.id,
          date: data.item.attendance.date,
        })
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    openManage(data) {
      console.log(data);

      // this.employee = data.item;
      console.log("Employee", this.employee);

      const item = {};

      item.status = data.item.status;

      item.date = data.item.date;

      if (data.item.attendance) item.attendance = data.item.attendance;

      this.attendance = item;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-manage-modal");
    },

    showUpdateModal(data) {
      console.log(data);

      // this.employee = data.item;
      console.log("Employee", this.employee);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk("Teacher doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      this.att = null;

      this.att = {
        id: data.item.id,
        date: data.item.date,
        in_fmt: data.item.attendance.time_in_fmt,
        out_fmt: data.item.attendance.time_out_fmt,
      };
      console.log("Attendance", this.att);

      this.$bvModal.show("common-attendance-update-time-modal");
    },

    /**
     * Calls fetchTeacherIndividualAttendance action to refresh table.
     *
     * @param form
     */
    handleManageSubmission(form) {
      console.log(form);

      this.fetch();

      this.$store.dispatch("fetchTeacherMonthlyAttendanceList");
    },

    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setTeacherIndividualAttendancePage", page);
      this.fetch();
    },

    /**
     * Fetching Individual Teacher Attendance
     */
    fetch() {
      this.$store.dispatch("fetchTeacherIndividualAttendance", {
        id: this.employee.id,
        date: this.dateInfo.date,
        date_type: this.dateInfo.date_type,
        from_date: this.dateInfo.from_date ?? dayjs().format(),
        to_date: this.dateInfo.to_date ?? dayjs().format(),
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherIndividualAttendanceList",
      pagination: "getTeacherIndividualAttendancePagination",
      records: "getTeacherClockRecordList",
      fetchBusy: "getTeacherIndividualAttendanceBusy",
    }),
  },
};
</script>

<style scoped></style>
